import {
  iconPostMeeting,
  iconUnPostMeeting,
  iconRecall,
  iconSubmit
} from "@/design/icon/iconConst";

/**
 * agenda Meeting Operation Type
 * @type {Readonly<{unpost: string, post: string, mediaManagerExport: string, unpublish: string, publish: string, mediaManagerImport: string}>}
 */
const agendaMeetingOperation = Object.freeze({
  post: "EventPost",
  unpost: "EventUnpost",
  publish: "EventPublish",
  unpublish: "EventUnpublish",
  mediaManagerImport: "MediaManagerImport",
  mediaManagerExport: "MediaManagerExport"
});

/**
 * agenda Meeting Operations
 * @type {{name: string, icon: string, description: string, label: string}[]}
 */
const agendaMeetingOperations = Object.freeze([
  {
    name: agendaMeetingOperation.post,
    label: "Post",
    description:
      "Post Agenda Meeting, so that Agenda Meeting can be used by other departments",
    icon: iconPostMeeting
  },
  {
    name: agendaMeetingOperation.unpost,
    label: "Unpost",
    description:
      "Un-post Agenda Meeting, so that Agenda Meeting cannot be used by other departments",
    icon: iconUnPostMeeting
  },
  {
    name: agendaMeetingOperation.publish,
    label: "Publish",
    description:
      "Publish Agenda Meeting, so that Agenda Meeting can be used by public",
    icon: iconSubmit
  },
  {
    name: agendaMeetingOperation.unpublish,
    label: "Unpublish",
    description:
      "Un-publish Agenda Meeting, so that Agenda Meeting cannot be used by public",
    icon: iconRecall
  }
]);

/**
 * find Agenda Meeting Operation
 * @param name name of Agenda Meeting Operation
 * @return {{name: string, icon: string, description: string, label: string}}
 */
const findAgendaMeetingOperation = name =>
  agendaMeetingOperations?.find(el => el.name === name);

export {
  agendaMeetingOperation,
  agendaMeetingOperations,
  findAgendaMeetingOperation
};
