// services
import { isRecordExpandable } from "@/services/record/recordService";

// mixins
import { moduleItemNavigatable } from "@/mixins/shared/navigatable/moduleItemNavigatable";
import {
  createModuleChildRecordContentRouteLocation,
  createModuleChildRecordShortcutContentRouteLocation,
  createModuleRecordRouteLocation
} from "@/router/solution/solutionRoutes";

/**
 * Contains options (adhere to the DRY principle) & code re-usability
 * for components that needs Application module record navigatable behavior
 */
export const moduleRecordNavigatable = {
  mixins: [moduleItemNavigatable],
  methods: {
    /**
     * navigate To Module Record
     * @param record
     * @return {Promise<void>}
     */
    async navigateToModuleRecord(record) {
      if (record && this.canNavigateToModuleRecord(record)) {
        await this.$router.push(this.createModuleRecordRouteLocation(record));
      }
    },

    /**
     * can Navigate To Module Record
     * @param {{id:number, name:string, categoryId:number, parentId:number, children:number, createdBy:string, creationDate:string, isDeleted:boolean, isDraft:boolean, isComposite:boolean, isLink:boolean, isLocked:boolean, isReadOnly:boolean, recordTypeId:number, stateId:number, state:string, version:number, versionDate:string, versionOwner:string, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {boolean|Boolean}
     */
    canNavigateToModuleRecord(record) {
      return isRecordExpandable(record)
        ? this.canNavigateToModuleItem(record?.id ?? -1)
        : this.canNavigateToModuleChildRecord(record);
    },

    /**
     * navigate To Module Child Record By id
     * @param {Number|number} id record id
     * @param {Number|number} parentId parent record id
     * @return {Promise<void>}
     */
    async navigateToModuleChildRecordById(id, parentId) {
      if (!this.canNavigateToChildRecordById(id, parentId)) {
        console.warn(
          `Couldn't navigate to ${this.appModuleName} by id: ${id} and expanded Id: ${parentId}`
        );
        return;
      }

      try {
        await this.$router.push(
          this.createModuleChildRecordRouteLocationById(id, parentId)
        );
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * create Module Child Record Content Route
     * @param {{id:number, name:string, categoryId:number, parentId:number, children:number, createdBy:string, creationDate:string, isDeleted:boolean, isDraft:boolean, isComposite:boolean, isLink:boolean, isLocked:boolean, isReadOnly:boolean, recordTypeId:number, stateId:number, state:string, version:number, versionDate:string, versionOwner:string, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {{name: (String|string), params: {expandedId: (Number|number), id: (Number|number)}}}
     */
    createModuleChildRecordContentRouteLocation(record) {
      return record.isLink ?? false
        ? createModuleChildRecordShortcutContentRouteLocation(
            this.appModuleName,
            record?.id ?? -1,
            record?.parentId ?? -1
          )
        : createModuleChildRecordContentRouteLocation(
            this.appModuleName,
            record?.id ?? -1,
            record?.parentId ?? -1
          );
    },

    /**
     * create Module Record Route Location
     * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {{name: (String|string), params: {id}}|{name: (String|string), params: {expandedId: (Number|number), id: (Number|number)}}}
     */
    createModuleRecordRouteLocation(record) {
      return createModuleRecordRouteLocation(this.appModuleName, record);
    },

    /**
     * create Module Child Record Target Route
     * @param {Number|number} id Record id
     * @param {Number|number} parentId parent record id
     * @return {{name: (String|string), params: {expandedId: (Number|number), id: (Number|number)}}}
     */
    createModuleChildRecordRouteLocationById(id, parentId) {
      return createModuleChildRecordContentRouteLocation(
        this.appModuleName,
        id,
        parentId
      );
    },

    /**
     * can Navigate To Current Module child Record
     * @param {{id:number, name:string, categoryId:number, parentId:number, children:number, createdBy:string, creationDate:string, isDeleted:boolean, isDraft:boolean, isComposite:boolean, isLink:boolean, isLocked:boolean, isReadOnly:boolean, recordTypeId:number, stateId:number, state:string, version:number, versionDate:string, versionOwner:string, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {Boolean|boolean}
     */
    canNavigateToModuleChildRecord(record) {
      return this.canNavigateToChildRecordById(
        this.appModuleName,
        record?.id ?? -1,
        record?.parentId ?? -1
      );
    },

    /**
     * can Navigate To Module Child Record By id
     * @param {Number|Number} id record id
     * @param {Number|Number} parentId parent record id
     * @return {boolean} true if navigatable To Module Tree Item
     */
    canNavigateToModuleChildRecordById(id, parentId) {
      return this.canNavigateToChildRecordById(
        this.appModuleName,
        id,
        parentId
      );
    }
  }
};
