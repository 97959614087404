import {
  agendaPacketContent,
  agendaPacketTabOptions
} from "@/model/agenda/agendaModel";

/**
 * Get Agenda Packet Numbering Options
 * @param optionModel {{downOptions: [{disabled: boolean, text: string, value: number}, {disabled: boolean, text: string, value: number}, {disabled: boolean, text: string, value: number}], item: boolean, includeLinkBackUp: boolean, pagesSuffixValue: string, itemTitleLengthValue: string, itemTitle: boolean, pagePrefixValue: string, linkNote: string, includeLinkAgenda: boolean, upOptions: [{disabled: boolean, text: string, value: number}, {disabled: boolean, text: string, value: number}, {disabled: boolean, text: string, value: number}], itemTitlePrefixValue: string, currentTab: number, pagesPrefixValue: string, includePageSeparator: boolean, numberPosition: number, linkStaticText: string, itemSuffixValue: string, pages: boolean, pageSuffixValue: string, itemPrefixValue: string, page: boolean, agendaItemTitle: string}}
 */
const getAgendaPacketNumberingOptions = optionModel => {
  const numberingOptionList = [];
  if (optionModel.currentTab === agendaPacketTabOptions.noNumbering)
    return numberingOptionList;

  if (optionModel.item)
    numberingOptionList.push(
      agendaPacketItemPrefix(optionModel.itemPrefixValue),
      agendaPacketItemSuffix(optionModel.itemSuffixValue)
    );

  if (optionModel.itemTitle)
    numberingOptionList.push(
      agendaPacketItemTitleSuffix(optionModel.itemTitlePrefixValue),
      agendaPacketItemTitleLength(optionModel.itemTitleLengthValue)
    );

  if (optionModel.page)
    numberingOptionList.push(
      agendaPacketPagePrefix(optionModel.pagePrefixValue),
      agendaPacketPageSuffix(optionModel.pageSuffixValue)
    );

  if (optionModel.pages)
    numberingOptionList.push(
      agendaPacketPageCountPrefix(optionModel.pagesPrefixValue),
      agendaPacketPageCountSuffix(optionModel.pagesSuffixValue)
    );

  return numberingOptionList;
};

/**
 * Get Agenda Packet Item Prefix
 * @param prefix
 * @return {{value, key: string}}
 */
const agendaPacketItemPrefix = prefix => {
  return {
    key: agendaPacketContent.itemPrefix,
    value: prefix
  };
};

/**
 *  Get Agenda Packet Item Suffix
 * @param suffix
 * @return {{value, key: string}}
 */
const agendaPacketItemSuffix = suffix => {
  return {
    key: agendaPacketContent.itemSuffix,
    value: suffix
  };
};

/**
 * Get Agenda Packet Item Title Suffix
 * @param suffix
 * @return {{value, key: string}}
 */
const agendaPacketItemTitleSuffix = suffix => {
  return {
    key: agendaPacketContent.itemTitlePrefix,
    value: suffix
  };
};

/**
 * Get Agenda Packet Item Title Length
 * @param length
 * @return {{value, key: string}}
 */
const agendaPacketItemTitleLength = length => {
  return {
    key: agendaPacketContent.itemTitleLength,
    value: length
  };
};

/**
 * Get Agenda Packet Page PrefixPrefix
 * @param prefix
 * @return {{value, key: string}}
 */
const agendaPacketPagePrefix = prefix => {
  return {
    key: agendaPacketContent.pagePrefix,
    value: prefix
  };
};

/**
 * Get Agenda Packet Page Suffix
 * @param suffix
 * @return {{value, key: string}}
 */
const agendaPacketPageSuffix = suffix => {
  return {
    key: agendaPacketContent.pageSuffix,
    value: suffix
  };
};

/**
 * Get Agenda Packet Page Count Prefix
 * @param prefix
 * @return {{value, key: string}}
 */
const agendaPacketPageCountPrefix = prefix => {
  return {
    key: agendaPacketContent.pageCountPrefix,
    value: prefix
  };
};

/**
 * Get Agenda Packet Page Count Suffix
 * @param suffix
 * @return {{value, key: string}}
 */
const agendaPacketPageCountSuffix = suffix => {
  return {
    key: agendaPacketContent.pageCountSuffix,
    value: suffix
  };
};

export { getAgendaPacketNumberingOptions };
