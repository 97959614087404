// router
import { createModuleRecordRouteLocation } from "@/router/solution/solutionRoutes";

// model
import { moduleNames } from "@/model/solution/moduleModel";

// services
import { isRecordExpandable } from "@/services/record/recordService";

// design
import { iconJumpToFolder } from "@/design/icon/iconConst";

// mixins
import { appMixin } from "@/mixins/shared/base/app/appMixin";
import { routerMixin } from "@/mixins/shared/router/routerMixin";
import { findRecordType, recordType } from "@/model/record/recordModel";
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

/**
 * Contains options (adhere to the DRY principle) & code re-usability
 * for components that needs Folder module record navigatable behavior
 */
export const folderRecordNavigatable = {
  mixins: [appMixin, routerMixin, publicAccessMixin],
  computed: {
    commandSelectRecordIconName: () => iconJumpToFolder,
    /**
     * Visible Jump To File
     * @return {string|boolean}
     */
    visibleJumpToFile() {
      return this.isPublicAccess
        ? this.publicAccess.modules.visibleFolder
        : true;
    }
  },
  methods: {
    /**
     * build Folder Module Select Record Route
     * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {{name: (String|string), params: {id}}|{name: (String|string), params: {id}}}
     */
    folderModuleRecordRouteLocation(record) {
      return createModuleRecordRouteLocation(moduleNames.Folder, record);
    },

    /**
     * can Navigate To Folder Module Record
     * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {boolean|*}
     */
    canNavigateToFolderModuleRecord(record) {
      return isRecordExpandable(record)
        ? this.canNavigateToFolderModuleRecordById(record?.id ?? -1)
        : this.canNavigateToFolderModuleChildRecordById(
            record?.id ?? -1,
            record?.parentId ?? -1
          );
    },

    /**
     * can Navigate To Folder Module Child Record By id
     * @param {Number|number} id Record id
     * @param {Number|number} expandedId Record's parent id
     * @return {boolean}
     */
    canNavigateToFolderModuleChildRecordById(id, expandedId) {
      return this.canNavigateToChildRecordById(
        moduleNames.Folder,
        id,
        expandedId
      );
    },

    /**
     * can Navigate To Folder Module Item
     * @param {Number} id Folder's record id
     * @return {boolean}
     */
    canNavigateToFolderModuleRecordById(id) {
      return this.canNavigateToModuleItemByModuleItemId(moduleNames.Folder, id);
    },

    /**
     * tooltip Navigate To Record (select/expand) record
     * @param {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}} record
     * @return {string} tooltip Navigate (select/expand) record
     */
    tooltipNavigateToRecord(record) {
      const action = isRecordExpandable(record) ? "Expand" : "Select";
      return `${action} ${record?.recordType}`;
    },

    /**
     * command Select Record Label
     * @param {{recordType: string, name: string, recordTypeId: number}} record
     * @return {string}
     */
    commandSelectRecordLabel(record) {
      const recordTypeName = findRecordType(
        record?.recordTypeId ?? recordType.RECORD
      )?.name;
      return this.appModuleName === moduleNames.Folder
        ? `Select ${recordTypeName}`
        : `Jump to ${recordTypeName}`;
    }
  }
};
