// model
import {
  agendaMeetingOperation,
  findAgendaMeetingOperation
} from "@/model/agenda/meeting/agendaMeetingModel";
import { recordType } from "@/model/record/recordModel";
import {
  agendaItemOperation,
  findAgendaItemOperation
} from "@/model/agenda/item/agendaItemModel";

// services
import { isAllowedAuthor } from "@/services/config/configService";
import { findOperation } from "@/services/record/recordCommonService";

/**
 * Determines whether Command of an Agenda Meeting should be visible
 * @param {{id:number, name:string, recordTypeId:number, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
 * @param {string} operation Record Operation
 * @return {Boolean|boolean} true if Command of Agenda Meeting should be visible
 */
const visibleAgendaMeetingCommandOperation = (record, operation) => {
  return isSupportedAgendaMeetingOperation(record, operation)
    ? isAllowedAgendaMeetingOperation(record, operation)
    : false;
};

/**
 * Determines whether Command of an Agenda Meeting should be enabled
 * @param {{id:number, name:string, recordTypeId:number, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
 * @param {string} operation
 * @return {Boolean|boolean} true if Command of Agenda Meeting should be enabled
 */
const enableAgendaMeetingCommandOperation = (record, operation) => {
  return (
    visibleAgendaMeetingCommandOperation(record, operation) &&
    isValidAgendaMeetingOperation(record, operation)
  );
};

/**
 * Determines whether Command of an Agenda Item should be visible
 * @param {{id:number, name:string, recordTypeId:number, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
 * @param {string} operation Record Operation
 * @return {Boolean|boolean} true if Command of Agenda Item should be visible
 */
const visibleAgendaItemCommandOperation = (record, operation) => {
  return isSupportedAgendaItemOperation(record, operation)
    ? isAllowedAgendaItemOperation(record, operation)
    : false;
};

/**
 * Determines whether Command of an Agenda Item should be enabled
 * @param {{id:number, name:string, recordTypeId:number, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
 * @param {string} operation
 * @return {Boolean|boolean} true if Command of Agenda Item should be enabled
 */
const enableAgendaItemCommandOperation = (record, operation) => {
  return (
    visibleAgendaItemCommandOperation(record, operation) &&
    isValidAgendaItemOperation(record, operation)
  );
};

/**
 * Determine whether current user (principal) is allowed to perform specified Agenda Meeting Operation
 * @param record
 * @param {String|string} operation name
 * @return {Boolean|boolean} true if current user (principal) is allowed to perform specified Agenda Meeting Operation
 */
const isAllowedAgendaMeetingOperation = (record, operation) => {
  return findOperation(record?.meetingOperations, operation)?.allowed ?? false;
};

/**
 * Determine whether current user (principal) is allowed to perform specified Agenda Item Operation
 * @param record
 * @param {String|string} operation name
 * @return {Boolean|boolean} true if current user (principal) is allowed to perform specified Agenda Item Operation
 */
const isAllowedAgendaItemOperation = (record, operation) => {
  return (
    findOperation(record?.agendaItemOperations, operation)?.allowed ?? false
  );
};

/**
 * Determines whether provided Agenda meeting operation is supported
 * @param {{id:number, name:string, recordTypeId:number, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
 * @param {string} operation record Operation
 * @return {boolean|Boolean} true if provided Agenda meeting operation is supported
 */
const isSupportedAgendaMeetingOperation = (record, operation) => {
  const op = findAgendaMeetingOperation(operation);
  if (!op) return false;

  switch (op.name) {
    case agendaMeetingOperation.post: {
      return supportAgendaMeetingPost(record);
    }
    case agendaMeetingOperation.unpost: {
      return supportAgendaMeetingUnPost(record);
    }
    case agendaMeetingOperation.publish: {
      return supportAgendaMeetingPublish(record);
    }
    case agendaMeetingOperation.unpublish: {
      return supportAgendaMeetingUnPublish(record);
    }
    default: {
      return false;
    }
  }
};

/**
 * Determines whether provided Agenda Item operation is supported
 * @param {{id:number, name:string, recordTypeId:number, operations: {name: string, allowed: boolean, valid: boolean}[]}} record
 * @param {string} operation record Operation
 * @return {boolean|Boolean} true if provided Agenda item operation is supported
 */
const isSupportedAgendaItemOperation = (record, operation) => {
  const op = findAgendaItemOperation(operation);
  if (!op) return false;

  switch (op.name) {
    case agendaItemOperation.defer: {
      return supportAgendaItemDefer(record);
    }
    case agendaItemOperation.refer: {
      return supportAgendaItemRefer(record);
    }
    case agendaItemOperation.recall: {
      return supportAgendaItemRecall(record);
    }
    case agendaItemOperation.submit: {
      return supportAgendaItemSubmit(record);
    }
    default: {
      return false;
    }
  }
};

/**
 * Determines whether specified record supports Post Agenda Meeting
 * @param {{id:number, name:string, recordTypeId:number}} record
 * @return {boolean} true if specified record supports Post Agenda Meeting
 */
const supportAgendaMeetingPost = record =>
  isAllowedAuthor() &&
  (record?.recordTypeId ?? recordType.RECORD) === recordType.MEETING;

/**
 * Determines whether specified record supports Un-Post Agenda Meeting
 * @param {{id:number, name:string, recordTypeId:number}} record
 * @return {boolean} true if specified record supports Un-Post Agenda Meeting
 */
const supportAgendaMeetingUnPost = record =>
  isAllowedAuthor() &&
  (record?.recordTypeId ?? recordType.RECORD) === recordType.MEETING;

/**
 * Determines whether specified record supports Publish Agenda Meeting
 * @param {{id:number, name:string, recordTypeId:number}} record
 * @return {boolean} true if specified record supports Publish Agenda Meeting
 */
const supportAgendaMeetingPublish = record =>
  isAllowedAuthor() &&
  (record?.recordTypeId ?? recordType.RECORD) === recordType.MEETING;

/**
 * Determines whether specified record supports Un-Publish Agenda Meeting
 * @param {{id:number, name:string, recordTypeId:number}} record
 * @return {boolean} true if specified record supports Un-Publish Agenda Meeting
 */
const supportAgendaMeetingUnPublish = record =>
  isAllowedAuthor() &&
  (record?.recordTypeId ?? recordType.RECORD) === recordType.MEETING;

/**
 * Determines whether specified record supports Refer Agenda Item
 * @param {{id:number, name:string, recordTypeId:number}} record
 * @return {boolean} true if specified record supports Refer Agenda Item
 */
const supportAgendaItemRefer = record =>
  isAllowedAuthor() &&
  (record?.recordTypeId ?? recordType.RECORD) === recordType.ITEM;

/**
 * Determines whether specified record supports Refer Agenda Item
 * @param {{id:number, name:string, recordTypeId:number}} record
 * @return {boolean} true if specified record supports Refer Agenda Item
 */
const supportAgendaItemDefer = record =>
  isAllowedAuthor() &&
  (record?.recordTypeId ?? recordType.RECORD) === recordType.ITEM;

/**
 * Determines whether specified record supports Submit Agenda Item
 * @param {{id:number, name:string, recordTypeId:number}} record
 * @return {boolean} true if specified record supports Submit Agenda Item
 */
const supportAgendaItemSubmit = record =>
  isAllowedAuthor() &&
  (record?.recordTypeId ?? recordType.RECORD) === recordType.ITEM;

/**
 * Determines whether specified record supports Recall Agenda Item
 * @param {{id:number, name:string, recordTypeId:number}} record
 * @return {boolean} true if specified record supports Recall Agenda Item
 */
const supportAgendaItemRecall = record =>
  isAllowedAuthor() &&
  (record?.recordTypeId ?? recordType.RECORD) === recordType.ITEM;

/**
 * Determine whether operation is valid (can Agenda Meeting Operation be performed under current circumstances)
 * @param record
 * @param {String|string} operation
 * @return {Boolean|boolean} true if Agenda Meeting Operation can be performed under current circumstances
 */
const isValidAgendaMeetingOperation = (record, operation) => {
  return findOperation(record?.meetingOperations, operation)?.valid ?? false;
};

/**
 * Determine whether operation is valid (can Agenda Item Operation be performed under current circumstances)
 * @param record
 * @param {String|string} operation
 * @return {Boolean|boolean} true if Agenda Item Operation can be performed under current circumstances
 */
const isValidAgendaItemOperation = (record, operation) => {
  return findOperation(record?.agendaItemOperations, operation)?.valid ?? false;
};

export {
  visibleAgendaMeetingCommandOperation,
  enableAgendaMeetingCommandOperation,
  visibleAgendaItemCommandOperation,
  enableAgendaItemCommandOperation,
  isAllowedAgendaItemOperation,
  isValidAgendaMeetingOperation,
  isValidAgendaItemOperation
};
